@font-face {
    font-family:'d-din';
    src: url("./D-DIN.ttf");
}

@font-face {
    font-family:'d-din-bold';
    src: url("./D-DIN-Bold.ttf");
}


@font-face {
    font-family:'Righteous-Regular';
    src: url("./Righteous-Regular.ttf");
}



